import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Typography } from '@mui/material';
import { useSelector } from '../../../../redux/store';
export default function PriceSection() {
    const data = useSelector((state) => state.dent.dents);
    const price = data?.reduce((accumulator, product) => {
        if (product.confirmed) {
            return accumulator + Number(product?.price);
        }
        return accumulator;
    }, 0);
    return (_jsxs(Card, { sx: {
            width: '30%',
            height: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            justifyContent: 'center',
            padding: '10px 20px',
            marginTop: '',
            flexDirection: 'column',
            alignItems: 'center',
        }, children: [_jsx(Typography, { variant: "body1", color: 'text.secondary', sx: { marginTop: '20px', fontWeight: 'bold' }, children: "Prix estim\u00E9" }), _jsxs(Typography, { variant: "h2", color: 'text.secondary', sx: { fontWeight: 'bold' }, children: [price, " dt"] })] }));
}
